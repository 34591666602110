<div  class="pod-container">
    <ng-container *ngIf="!isLoading">
        <div class="header-info">
            <div class="info">
                <div class="task">
                    <span>Stop {{stopIndex}} - {{stopType}} </span>
                    <span *ngIf="!noPod">{{displayInfo.presentTask}}</span>
                </div>
                <div *ngIf="!noPod" class="pod-info">Media {{displayInfo.presentMedia}} of {{displayInfo.totalMedia}}</div>
                <div *ngIf="!noPod" class="pod-info" style="font-style: italic;">
                    <div class="name">Upload by <create-by-user [createBy]="displayInfo.uploadBy" [styleFontWeight]="500"></create-by-user>
                        <span class="date">, {{formatDate(displayInfo.uploadWhen)}}</span>
                    </div>
                    
                </div>
            </div>
            <div *ngIf="!noPod" class="assign-pod">
                <div class="applied-tasks" [nz-popover] nzPopoverTrigger="click" nzPopoverTitle="Assign POD" [nzPopoverContent]="contentTemplate"
                    nzPopoverPlacement="left">
                  Applied for {{appliedPodText}} <span nz-icon nzType="edit" nzTheme="outline"></span>
                  <ng-template #contentTemplate>
                    <pod-confirmation-assign-pod [stops]="displayInfo.displayStop" [tasks]="displayInfo.tasks || []" [podItem]="presentPod" [refreshData]="refreshDataAfterAssignPod"></pod-confirmation-assign-pod>
                  </ng-template>
                </div>
            </div>
        </div>
        <div class="view-pod">
            <div class="pod-tool">
                <div *ngIf="!noPod" class="btn">
                    <span nz-icon style="font-size: 18px; cursor: pointer;" nzType="left-circle" nzTheme="outline" (click)="onBtnBack()"></span>
                </div>
                <div class="pod">
                    <div class="pod-item-container"  id="pod-modal-item" *ngIf="!noPod">
                        <div *ngIf="!isPdf(presentPod)" app-ngx-viewer [src]="presentPod?.localUrl" [nextFn]="onBtnNext" [backFn]="onBtnBack"></div>
                        <div *ngIf="isPdf(presentPod)" style="width: 100%; ">
                          <pdf-pod [src]="presentPod?.localUrl" [pod]="presentPod" [nextFn]="onBtnNext" [backFn]="onBtnBack"></pdf-pod>
                        </div>
                    </div>
                    <div class="pod-item-container" *ngIf="noPod">
                        <nz-result nzStatus="404" nzTitle="No POD" nzSubTitle="This Stop hasn't had any pod. Upload to see.">
                        </nz-result>
                    </div>
                </div>
                <div *ngIf="!noPod" class="btn">
                    <span nz-icon style="font-size: 18px;cursor: pointer;" nzType="right-circle" nzTheme="outline" (click)="onBtnNext()"></span>
                </div>
            </div>
        </div>
        <div *ngIf="!noPod" class="footer">
            <div class="tool">
                <div class="group-btn" style="color: #1802D0;" (click)="onBtnDownload(presentPod)">
                    <img src="/assets/img/sim_card_download.png" />
                    <span>Download</span>
                </div>
                <div class="group-btn" style="color: #1802D0;" (click)="onBtnViewIssue()">
                    <span  class="f20" nz-icon nzType="info-circle" nzTheme="twotone" [nzTwotoneColor]="'#1802D0'"></span>
                    <span >Issue</span>
                    <div>(<span *ngIf="!isLoadingIssue">{{displayInfo?.totalIssue}}</span>
                        <span *ngIf="isLoadingIssue" nz-icon nzType="loading" nzTheme="outline"></span>)
                    </div>
                </div>
                <div class="group-btn" (click)="onBtnDelete(presentPod)">
                    <img src="/assets/img/dispatch-icons/delete.svg" />
                    <span style="color: #C01318;">Remove</span>
                </div>
            </div>
            <div class="btn-confirm">
                <button *ngIf="!isConfirmPOD(presentPod)" nz-button nzType="primary" (click)="onBtnConfirm(presentPod)" [nzLoading]="presentPod.onProgress">Confirm</button>
                <div *ngIf="isConfirmPOD(presentPod)" class="unconfirm">
                    <div class="confirm-by">Confirmed by <create-by-user [createBy]="presentPod?.podConfirmed" [styleTextColor]="'#3f9714'"></create-by-user>, {{formatDate(presentPod.podConfirmed.when)}}</div>
                    <a (click)="onBtnUnConfirm(presentPod)"><span nz-tooltip nzTooltipTitle="Undo confirm" nzTooltipPlacement="topRight" nz-icon nzType="redo" nzTheme="outline"></span></a>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="isLoading">
        <div style="height: 70vh; display: flex; align-items: center; justify-content: center;">
            <div  class="nodata" ><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        </div>
    </ng-container>
</div>
