<div class="task">
    <div class="info">
        <div style="font-weight: 600;">
            <a [routerLink]="displayInfo.routerLink" target="_blank" style="color: #191A23;">{{displayInfo.shipmentWarpIdText}}</a>
            <span nz-icon nzType="copy" nzTheme="outline" (click)="copyText(displayInfo.shipmentWarpId)" style="cursor: pointer; margin-left: 5px;"></span>
        </div>
        <div>
            <span class="label">Customer: </span> 
            <span><a style="color: #191A23;" [routerLink]="[routeAdminClientList,displayInfo.clientId]" target="_blank">{{displayInfo.client}}</a></span>
        </div>
        <div class="refNum">
            <span class="label">Ref no: </span>
            <span *ngIf="!isShowRef()">N/A</span>
            <ng-container *ngIf="isShowRef()" >
                <nz-tag *ngFor="let ref of displayInfo.refNums" [nzColor]="'rgba(51, 65, 85, 0.12)'" style="color: #191A23; font-size: 12px; font-weight: 400; max-width: 440px; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;" [nzTooltipTitle]="ref" nzTooltipPlacement="bottom" nz-tooltip>{{ ref }}</nz-tag>
            </ng-container>
        </div>
        <div *ngIf="isShowOrderRef()" class="refNum">
            <span class="label">Order ref no: </span>
            <span *ngIf="!hasOrderRef()">N/A</span>
            <ng-container *ngIf="hasOrderRef()" >
                <nz-tag *ngFor="let ref of displayInfo.orderRefNums" [nzColor]="'rgba(51, 65, 85, 0.12)'" style="color: #191A23; font-size: 12px; font-weight: 400; max-width: 440px; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;" [nzTooltipTitle]="ref" nzTooltipPlacement="bottom" nz-tooltip>{{ ref }}</nz-tag>
            </ng-container>
        </div>
        <div>
            <nz-timeline>
                <nz-timeline-item [nzDot]="pickup">{{displayInfo.pickupAddr}}</nz-timeline-item>
                <nz-timeline-item [nzDot]="dropoff">{{displayInfo.dropoffAddr}}</nz-timeline-item>
            </nz-timeline>
        </div>
    </div>
    <div class="pods" list-pod-pod-confirmation [pods]="displayInfo.pods" [taskId]="displayInfo.taskId" [stop]="stop"></div>
</div>
<ng-template #pickup>
    <div 
        style=" display: flex;
                width: 20px;
                height: 20px;
                padding: 3.333px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 8.333px;
                border-radius: 83.333px;
                border: 1px solid #8D8D8D;
                background: #FFF;"
    >
        <span style="color:#1802D0; font-size: 10px; font-weight: 500;">PU</span>
    </div>
</ng-template>
<ng-template #dropoff>
    <div 
        style=" display: flex;
                width: 20px;
                height: 20px;
                padding: 3.333px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 8.333px;
                border-radius: 83.333px;
                border: 1px solid #8D8D8D;
                background: #FFF;"
    >
        <span style="color:#1802D0; font-size: 10px; font-weight: 500;">DO</span>
    </div>
</ng-template>