import { Component, Input, OnInit } from '@angular/core';
import { PODLocalFile, PodService } from '../../pod.service';
import { BaseComponent } from '@abstract/BaseComponent';
import StopEntity from '../../Entity/StopEntity';
import { DateUtil } from '@services/date-utils';
import TaskEntity from '../../Entity/TaskEntity';
import { WarpId } from '@wearewarp/universal-libs';
import { Utils } from '@services/utils';
import { Const } from '@const/Const';
import { AppointmentInfo } from '@wearewarp/types/data-model';

@Component({
  selector: '[pod-confirmation-stop-detail]',
  templateUrl: './stop-detail.component.html',
  styleUrls: ['./stop-detail.component.scss']
})
export class PodConfirmationStopDetail extends BaseComponent{
  @Input() stop: StopEntity;
  @Input() index: number;
  public podArray: PODLocalFile[];
  public isLoading: boolean = true;
  public displayInfo: any;
  public expanded: boolean = true;
  public expandShipments: boolean = false;
  constructor(private podService: PodService) {
    super();
  }

  ngOnChanges(){
    if(!stop) return;
    this.podArray = this.podService.getPodByStop(this.stop.getId());
    this.buildDisplayInfo()
  }

  ngOnInit(): void {
    //stop change
    // this.subscription.add(
    //   this.podService.onSelectedStop.subscribe(
    //     (stop: StopEntity | null) => {
    //       if(!stop) return;
    //       this.stop = stop;
    //       this.podArray = this.podService.getPodArray();
    //       this.buildDisplayInfo();
    //     }
    //   )
    // );
    //loading
    this.subscription.add(
      this.podService.loading.subscribe(
        (loading: boolean) => {
          this.isLoading = loading;
        }
      )
    )

    //POD change
    this.subscription.add(
      this.podService.selectedPod.subscribe(
        (data: PODLocalFile | null) => {
          if(!data) return;
          let focusElement : HTMLElement;
          if(data?.taskId){
            focusElement = document.getElementById(`task-${data.taskId}`);
          }else{
            focusElement = document.getElementById(`stop-${data.stopId}`);
          }
          if(focusElement) this.scrollToElement(focusElement, { behavior: 'smooth' });
        }
      )
    )
  }

  private buildDisplayInfo(){
    const displayInfo = {
      id: this.stop.getId(),
      podStopLevel: this.getPODStopLevel(this.podArray),
      addr: this.stop.getAddressText(),
      locationName: this.stop.getLocationName(),
      deliveryInfo: this.stop.getDeliveryInfo(),
      actualArrived: this.getDisplayTime(this.stop.getArrivedTime()),//arrived
      refNums: this.stop.getRefNums(),
      isShowRefNumsFull: this.isShowRefNumsFull(),
      refNums_Short: this.getRefNums_Short(),
      refNums_More: this.getMoreRefNums(),
      tasks: this.buildTaskDisplayInfo(),
      isScheduled: this.stop.isAppointmentSchduled(),
      appointment: this.getDisplayTimeWindow(this.stop.getAppointment(), this.stop.getTimezone()),
      timezone: this.stop.getTimezone()
    }
    this.displayInfo = displayInfo;
  }

  public getTypeText(item: StopEntity){
    return item.getType() === Const.TaskType.PICKUP? 'PU':'DO';
  }

  private getPODStopLevel(podArray: PODLocalFile[]){
    return podArray.filter(it => !it?.taskId)
  }

  private getDisplayTime(time) {
    return DateUtil.displayLocalTime(time,
      {
        timezone: this.stop.getTimezone(),
        format: 'MM/DD/YY h:mm A'
      }
    )
  }

  private getDisplayTimeWindow(appointment: AppointmentInfo, timezone: string) {
    return DateUtil.displayTimeWindow(appointment, {
      timezone: timezone,
      formatDateOnly: 'MM/DD/YY',
      format: "MM/DD/YY h:mm A",
    })
  }
  private getDisplayTimezone(timezone: string){
    return DateUtil.timezoneStandardToUsShort(timezone)
  }

  private isShowRefNumsFull() {
    let refNums = this.stop.getRefNums() || [];
    return refNums.length <= 2;
  }

  private getRefNums_Short() {
    let refNums = this.stop.getRefNums() || [];
    if (refNums.length <= 2) {
      return this.stop.getRefNums();
    } else {
      return [refNums[0],refNums[1]];
    }
  }

  private getMoreRefNums() {
    let refNums = this.stop.getRefNums() || [];
    if (refNums.length > 2) {
      return refNums.length-2;
    }
  }

  public buildTaskDisplayInfo(){
    const tasks = this.stop.getTasks();
    const routeTasks = this.podService.getTaskEntities();
    const displayInfo = [];
    for(let task of tasks){
      const obj: any = {};
      obj.taskId = task.getId();
      obj.shipmentWarpIdText = this.getWarpIdText(task);
      obj.shipmentWarpId = task.getShipment().getCode();
      obj.refNums = task.getRefNums();
      let pickupTask = routeTasks.find(it => (it.getShipmentId() === task.getShipmentId() && it.getType() === Const.TaskType.PICKUP));
      obj.pickupAddressText = pickupTask.getAddressText();
      obj.pickupLocation = pickupTask.getLocationName();
      let dropoffTask = routeTasks.find(it => (it.getShipmentId() === task.getShipmentId() && it.getType() === Const.TaskType.DROPOFF));
      if(dropoffTask){
        obj.dropoffAddressText = dropoffTask.getAddressText();
        obj.dropoffLocation = dropoffTask.getLocationName();
        dropoffTask.getRefNums().forEach(it => {
          if(!obj.refNums.includes(it)) obj.refNums.push(it)
        })
      };
      obj.pods = this.podArray.filter(it => it?.taskId === task.getId());
      let shipmentEntity = task.getShipment();
      obj.orderRefNums = shipmentEntity.getOrderRefs();
      obj.shipmentTransitType = shipmentEntity.getShipmentTransitType();
      obj.client = shipmentEntity.getClient();
      obj.routerLink = this.getRouterLink(task)
      displayInfo.push(obj);;
    }
    
    return displayInfo;
  }

  isShowRef(): boolean{
    return this.displayInfo.refNums.length > 0;
  }

  public getWarpIdText(task: TaskEntity) {
    return task.getShipment().getCodeText();
    // return WarpId.showShipment(task.getShipment()?.getWarpId());
  }

  getRouterLink(item) {
    item = item?.getShipment()?.toJSON()
    if ([Const.ShipmentTransitType.leg, Const.ShipmentTransitType.layover].includes(item.shipmentTransitType)) {
      let orderId = item.metadata?.parent?.orderId || '';
      return [Const.routeAdminOrderList, orderId];
    }
    if (item?.orderId) {
      return [Const.routeAdminOrderList, item.orderId];
    }
    return [Const.routeAdminOrderList];
  }

  public hasData(){
    return Utils.isObjectNotEmpty(this.displayInfo)
  }

  public toggleCollapse() {
    this.expanded = !this.expanded
  }
  public toggleCollapseShipment() {
    this.expandShipments = !this.expandShipments
  }

  public getStatusText(stop: StopEntity){
    return Utils.capitalizeFirstLetter(stop.getStatus());
  }

  public getStatusColor(stop: StopEntity){
    switch(stop.getStatus()){
      case Const.TaskStatus.canceled:
      case Const.TaskStatus.pickupFailed:
      case Const.TaskStatus.failed: return '#EF4F44';
      case Const.TaskStatus.created: return '#A5ADBA';
      case Const.TaskStatus.enroute: return '#0065FF';
      case Const.TaskStatus.succeeded: return '#36B37E';
    }
  }

  

}
