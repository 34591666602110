import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import StopEntity from "../../Entity/StopEntity";


@Component({
  selector: "pod-confirmation-assign-pod",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class RouteAssignPodTasks extends BaseComponent {
  private _podItem: any;

  @Input() refreshData: Function;
  @Input() tasks: any[];
  @Input() stops: any[];
  @Input() set podItem(value) {
    this._podItem = value;
    this.initChecked();
    for(let key of Object.keys(this.selectedStops)){
      if(this.selectedStops[key]){
        this.podLevel = 0;
      }
    }
  }
  get podItem() {
    return this._podItem;
  }

  isSubmitting: boolean = false;
  checkedAllPods: boolean;
  indeterminatePods: boolean;
  checkAllStops: boolean;
  indeterminateStops: boolean;
  public selecteds: { [key: string]: boolean } = {};
  public selectedStops: { [key: string]: boolean} = {};
  public originalSelectedsShipmentLevel: { [key: string]: boolean};
  public originalSelectedsStopLevel: { [key: string]: boolean}
  public podLevel: number = 1;

  public initChecked() {
    this.selecteds = {};
    this.selectedStops = {};
    //khong co taskId thi la stop level
    this.tasks.forEach(it => {
      this.selecteds[it.id] = this.podItem?.tasks?.findIndex(task => task.id === it.id) != -1 ? true : false;
    });
    for(let stop of this.stops){
      let taskIds = stop?.tasks?.map(it => it?.getId());
      if(taskIds.length == 1) {
        this.selectedStops[stop.id] = false;
        continue;
      };
      this.selectedStops[stop.id] = taskIds.reduce((currentValue, taskId) => {
        return this.selecteds[taskId] && currentValue;
      }, true);
      if(this.selectedStops[stop.id]){
        taskIds.forEach(taskId => {
          this.selecteds[taskId] = false
        })
      }
    }
    this.originalSelectedsShipmentLevel = {...this.selecteds};
    this.originalSelectedsStopLevel = {...this.selectedStops};
    this.refreshCheckAllPods();
  }
  public refreshCheckAllPods() {
    this.checkedAllPods = this.tasks.every(it => this.selecteds[it.id]);
    this.indeterminatePods = this.tasks.some(it => this.selecteds[it.id]) && !this.checkedAllPods;
    this.checkAllStops = this.stops.every(it => this.selectedStops[it.id]);
    this.indeterminateStops = this.stops.some(it => this.selectedStops[it.id]) && !this.checkAllStops;
  }

  public onCheckAllPodsShipmentLevel(checked: boolean) {
    this.tasks.forEach(it => this.selecteds[it.id] = checked);
    this.refreshCheckAllPods();
  }

  public onCheckAllPodsStopLevel(check: boolean){
    this.stops.forEach(stop => {
      this.selectedStops[stop.id] = check;
    })
    this.refreshCheckAllPods();
  }

  public getTask(taskType) {
    return this.tasks.filter(it => it.type === taskType).sort((a, b) => a?.shipment?.warpId - b?.shipment?.warpId);
  }

  public getBtnText(){
    return 'Assign'
  }

  public async onSubmit() {
    // if (Object.values(this.selecteds).every(it => !it)) {
    //   this.confirmYesNo("You have unselected all tasks. The POD will be deleted. do you want to continue?", async () => {
    //     await this.updatePodTask();
    //   });
    //   return;
    // }
    await this.updatePodTask();
  }

  public getParamsUpdate(){
    if(this.podLevel){
      return this.tasks.map(it => ({ id: it.id, selected: this.selecteds[it.id] }))
    }else{
      let taskIdsChecked = [];
      this.stops.forEach(stop => {
        if(this.selectedStops[stop.id]){
          let taskIds = (stop?.tasks || []).map(it => it.getId())
          taskIdsChecked = [...taskIdsChecked,...taskIds]
        }
      })
      return this.tasks.map(it => {
        return {
          id: it.id,
          selected: taskIdsChecked.includes(it.id)
        }
      })
    }
  }
  public async updatePodTask() {
    this.isSubmitting = true;
    const jobId = this.tasks?.[0]?.job_id;
    const tasksBodyItems = this.getParamsUpdate()
    // console.log(jobId, this.podItem, this.tasks, this.selecteds)
    delete this.podItem.loadingTask; //prevent error Converting circular structure to JSON
    await this.api.POST(`${Const.APIV2(Const.APIURI_JOBS)}/${jobId}/update-pod-tasks`, {
      pod: this.podItem,
      tasks: tasksBodyItems
    }).toPromise();

    this.isSubmitting = false;
    this.refreshData(true);
    this.showSuccess("Update POD tasks successfully");
  }

  public isShipmentLevel(){
    return this.podLevel === 1;
  }
  public isStopLevel(){
    return this.podLevel === 0;
  }
  public shouldShowDanger(){
    if(this.podLevel){
      return !this.checkedAllPods && !this.indeterminatePods;
    }else{
      return !this.checkAllStops && !this.indeterminateStops;
    }
  }
  public shouldDisableAssignButton(){
    if(this.podLevel){
      if(!this.checkedAllPods && !this.indeterminatePods) return true;
      for(let key of Object.keys(this.selecteds)){
        if(this.selecteds[key] !== this.originalSelectedsShipmentLevel[key]) return false;
      }
      return true;
    }else{
      if(!this.checkAllStops && !this.indeterminateStops) return true;
      for(let key of Object.keys(this.selectedStops)){
        if(this.selectedStops[key] !== this.originalSelectedsStopLevel[key]) return false;
      }
      return true;
    }
  }
}