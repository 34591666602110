import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PODLocalFile, PodService } from '../../pod.service';
import { AttachedFile } from '@wearewarp/types/data-model';
import { AttachedFileUtil } from '@services/attached-file-util';
import * as pdfjsLib from "pdfjs-dist";
import { ImageUtil } from '@services/image-util';
import { BaseComponent } from '@abstract/BaseComponent';
import { Const } from '@const/Const';
import StopEntity from '../../Entity/StopEntity';
pdfjsLib.GlobalWorkerOptions.workerSrc = "assets/mozilla/pdfjs-2.13.216/pdf.worker.min.js";

@Component({
  selector: '[list-pod-pod-confirmation]',
  templateUrl: './list-pod.component.html',
  styleUrls: ['./list-pod.component.scss']
})
export class ListPodPodConfirmation extends BaseComponent{
  @Input() pods: PODLocalFile[] = [];
  @Input() stop : StopEntity;
  @Input() taskId: string='';
  @ViewChild('hasBeenConfirmed') hasBeenConfirm: TemplateRef<void>;
  @ViewChild('needConfirm') needConfirm: TemplateRef<void>
  public selectedPOD: PODLocalFile;
  public isUploading: boolean = false;
  constructor(private podService: PodService) {
    super()
   }

  ngOnInit(){
    this.subscription.add(
      this.podService.selectedPod.subscribe(
        (pod: PODLocalFile | null) => {
          this.selectedPOD = pod;
        }
      )
    )
  }
  ngOnChanges(): void {
    this.preparePodUrlForTask()
  }

  private async preparePodUrlForTask() {
    for (let i = 0; i < this.pods.length; i++) {
      let podItem = this.pods[i];
      if(!this.isPdf(podItem)) continue;
      this.loadPdfPod(podItem,i);
    }
  }

  public isPdf(item: AttachedFile): boolean {
    return AttachedFileUtil.isPdf(item);
  }

  public async loadPdfPod(podItem,index){
    let url = await podItem.localUrl();
    podItem.loadingTask = pdfjsLib.getDocument({url, withCredentials: true});
    podItem.loadingTask.promise.then(
        (pdf) => {
        pdf.getPage(1).then( (page) => {
          var desiredWidth = 48; // css class attached-pod
          var viewport = page.getViewport({ scale: 1 });
          var scale = desiredWidth / viewport.width;
          var scaledViewport = page.getViewport({ scale: scale });
          var canvas;
          if(podItem?.taskId)
            canvas = <HTMLCanvasElement>(
              document.getElementById(`task-${podItem.taskId}-pod-${index}`)
            );
          else 
            canvas = <HTMLCanvasElement>(
              document.getElementById(`stop-${this.stop.getId()}-pod-${index}`)
            );
          if (!canvas) return;
          var context = canvas.getContext("2d");
          canvas.height = scaledViewport.height;
          canvas.width = scaledViewport.width;
          var renderContext = {
            canvasContext: context,
            viewport: scaledViewport,
          };
          var renderTask = page.render(renderContext);
          renderTask.promise
            .then()
            .catch((e) => console.error("DPF render error ", e));
        });
      },
      function (err) {
        console.error("PDF loading error ", err);
      }
    );
  }

  public isSelected(item: PODLocalFile){
    //pod stop level ko có trường taskId
    if(this.selectedPOD?._id !== item?._id) return false;
    if(this.selectedPOD?.stopId !== item?.stopId) return false;
    if(item?.taskId){
      if(!this.selectedPOD?.taskId) return false;
      if(this.selectedPOD?.taskId !== item?.taskId) return false;
      return true;
    }else{
      if(this.selectedPOD?.taskId) return false;
    }
    return true;
  }

  handleClick(podId){
    if(this.taskId)
      this.podService.setSelectedPod(podId, this.stop.getId(), this.taskId)
    else 
      this.podService.setSelectedPod(podId, this.stop.getId())
  }

  isConfirmPOD(item) {
    if (item.podConfirmed?.by && item.podConfirmed?.when) return true;
    return false;
  }

  public getIndexForPod(podId: string){
    for(let i = 0; i < this.pods.length; i++){
      if(this.pods[i]?._id === podId) return i;
    }
  }

  getTemplateType(item){
    if(this.isConfirmPOD(item)) return this.hasBeenConfirm;
    return this.needConfirm;
  }

  onFileSelected(inputElement: HTMLInputElement) {
    let file = inputElement.files[0];
    inputElement.value = "";
    ImageUtil.resizeImageIfNeeded(file).then((blob) =>{
      if(this.taskId)
        this.uploadPOD(blob, file.name, [this.taskId], this.taskId);
      else {
        let tasks = this.stop.getTasks();
        this.uploadPOD(blob, file.name, tasks.map(task => task.getId()));
      }
    }
    );
  }

  private async uploadPOD(file: Blob, fileName: string, taskIds: string[], focusTaskIdAfterUpload?: string) {
    this.isUploading = true;
    let apiUrl = `${Const.APIV2(Const.APIURI_TASKS)}/uploadPodForListTasksOfStop`;
    let formData = new FormData();
    const jsonData = {
      'taskIds': taskIds,
    }
    formData.append("params", JSON.stringify(jsonData));
    formData.append("uploadPOD", file, fileName);
    this.api.postFormData(apiUrl, formData).subscribe(
      (resp) => {
        this.isUploading = false;
        if(focusTaskIdAfterUpload){
          this.refreshPodChange(focusTaskIdAfterUpload)
        }else{
          this.refreshPodChange('')
        }
        this.showSuccess("Your POD has been added successfully.", {nzPlacement: 'bottomRight'});
      },
      (err) => {
        this.isUploading = false;
        this.showErr(err, {nzPlacement: 'bottomRight'});
      }
    );
  }

  onClickAddPod(event){
    let element: HTMLElement;
    element = document.getElementById(`stop-${this.stop.getId()}-uploadRoutePODInput-${this.taskId}`) as HTMLElement;
    element.click();
    //event.stopPropagation();
  }

  private refreshPodChange(focusTaskId: string){
    let jobId = this.podService.getSelectedJob()?.id;
    let stopId = this.stop.getId();
    // let taskId = this.selectedPOD?.taskId
    this.podService.fetchRoute(jobId, stopId, this.selectedPOD?._id, focusTaskId, false, true);
  }

  

}
