import { BaseComponent } from '@abstract/BaseComponent';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { PODLocalFile, PodService } from '../../pod.service';
import { AttachedFileUtil } from '@services/attached-file-util';
import StopEntity from '../../Entity/StopEntity';
import TaskEntity from '../../Entity/TaskEntity';
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';
import { WarpId } from '@wearewarp/universal-libs';
import { Utils } from '@services/utils';
import to from 'await-to-js';
import { DrawerService } from '@app/drawers/drawer.service';
import { Issue } from '../issue/issue.component';

@Component({
  selector: '[pod-detail]',
  templateUrl: './pod-detail.component.html',
  styleUrls: ['./pod-detail.component.scss']
})
export class PodDetail extends BaseComponent{
  @HostListener('document:keyup',['$event'])
  handleKeyboardEvent(event: KeyboardEvent){
    if (event.key === 'ArrowLeft') {
      this.onBtnBack();
    } else if (event.key === 'ArrowRight') {
      this.onBtnNext();
    }
  }
  public isLoading: boolean = true;
  public presentPod: PODLocalFile;
  public fileUrl: string = null;
  public noPod: boolean;
  public selectedTask: TaskEntity;
  public displayInfo: any;
  public isLoadingPdfPOD: boolean = false;
  public stopIndex: number = 0;
  public stopType: string = '';
  public imageSrc: string = ''
  public imageViewOption: any = {
    backdrop:true,
    navbar: false ,
    inline: true,
    title:false,
    toolbar: {
      rotateLeft: 4,
      rotateRight: 4,
    },
  }
  public isLoadingIssue: boolean = true;
  constructor(private podService: PodService) {
    super();
    this.refreshDataAfterAssignPod = this.refreshDataAfterAssignPod.bind(this);
    this.onBtnBack = this.onBtnBack.bind(this);
    this.onBtnNext = this.onBtnNext.bind(this);
   }

  ngOnInit(): void {
    this.subscription.add(
      this.podService.loading.subscribe((data: boolean) => {
        this.isLoading = data;
      })
    );
    this.subscription.add(
      this.podService.selectedPod.subscribe(
        async (data: PODLocalFile | null) => {
          if(data){
            this.presentPod = data;
            if(data?.taskId){
              this.selectedTask = this.podService.getTaskById(data.taskId);
            }else this.selectedTask = null;
            this.buildDisplayInfo();
            this.countTaskOfPOD(this.presentPod);
            this.getIssue();
            this.noPod = false;
          }else{
           this.noPod = true;
           this.presentPod = null;
           this.isLoading = false
          }
        }
      )
    );
    this.subscription.add(
      this.podService.onSelectedStop.subscribe(
        (data: StopEntity | null) => {
          if(!data) return;
          this.stopIndex = this.podService.getSelectedStopIndex();
          this.stopType = data.getType();
        }
      )
    )
  }

  private buildDisplayInfo(){
    const displayInfo: any = {
      presentTask: this.getPresentTask(),
      tasks: this.getTasks(),
      applyTasks: this.getApplyTasks(),
      uploadBy: this.presentPod?.insert,
      uploadWhen: this.presentPod?.insert?.when,
      totalMedia: this.getTotalMedia(),
      presentMedia: this.getPresentMedia(),
      displayStop: this.getListStops()
    }
    this.displayInfo = displayInfo;
  }

  private getPresentMedia() {
    let medias = this.getTaskPOD();
    for(let i = 0; i< medias.length; i++){
      if(medias[i]?._id === this.presentPod?._id) return i+1;
    }
    return 0;
  }
  private getTaskPOD() {
    let podArray = this.podService.getPodArray() || [];
    let medias;
    if(this.presentPod?.taskId){
      medias = podArray.filter(it => it?.taskId && it?.taskId === this.presentPod?.taskId)
    }else{
      medias = podArray.filter(it => !it?.taskId)
    }
    return medias;
  }
  private getTotalMedia(){
    return this.getTaskPOD()?.length;
  }

  public isPdf(item: PODLocalFile): boolean {
    return AttachedFileUtil.isPdf(item);
  }

  public getTasks(){
    const tasks = this.podService.getSelectedJob()?.tasks || [];
    return tasks;
  }

  //cac task co POD la presentPod
  public getApplyTasks(){
    const tasks = this.getTasks();
    let podExistInTasks = tasks.filter(task => {
      const podArr = task?.podArr || [];
      return podArr.find(pod => pod._id == this.presentPod?._id)
    });
    return podExistInTasks || [];
  }

  public getPresentTask(){
    const taskId = this.presentPod?.taskId;
    if(!taskId) return '';
    let task = this.podService.getTaskById(taskId);
    if(!task) return '';
    let shipmentEntity = this.podService.getShipmentById(task.getShipmentId());
    if(!shipmentEntity) return '';
    let warpId = shipmentEntity.getWarpId();
    return this.showShipmentWarpId(warpId);
  }

  formatDate(date) {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }

  public showShipmentWarpId(shipment: number | { warpId: number }): string {
    return WarpId.showShipment(shipment);
  }

  public hasData(){
    return Utils.isObjectNotEmpty(this.displayInfo)
  }

  

  public refreshPodChange(isDelete?: boolean){
    let jobId = this.podService.getSelectedJob()?.id;
    let stopId = this.presentPod?.stopId;
    if(isDelete) return this.podService.fetchRoute(jobId, stopId, this.presentPod._id, this.selectedTask? this.selectedTask.getId() : '', true);
    this.podService.fetchRoute(jobId, stopId, this.presentPod._id, this.selectedTask? this.selectedTask.getId() : '');
  }

  onBtnConfirm = (item: PODLocalFile) => {
    let params: any = {};
    params.uploadPodFileId = item._id;
    if(item?.taskId){
      params.taskIds = [item.taskId]
    }else{
      params.taskIds = item.taskIds;
    }
    let url = `${Const.APIV2(Const.APIURI_TASKS)}/confirmPodForListTasksOfStop`;
    item.onProgress = true;
    this.api.POST(url, params).subscribe(
      (resp) => {
        item.podConfirmed = resp.data?.podConfirmed;
        item.onProgress = false;
        this.refreshPodChange();
        this.showSuccess("Your POD has been confirm successfully.");
      },
      (err) => {
        item.onProgress = false;
        this.showErr(err);
      }
    );
  };

  onBtnUnConfirm = async (item: PODLocalFile) => {
    this.confirmDeletion({
      message: "Are you sure to unconfirm this POD?",
      txtBtnOk: "Unconfirm",
      fnOk:  async () => {
       this.callApiUnConfirm(item);
      }
    });
  }

  async callApiUnConfirm(item : PODLocalFile){
    let params = { "id": item._id };
    let url = `${Const.APIURI_TASKS}/${item?.taskId ? item.taskId: item.taskIds[0]}/un-confirm-pod`;
    item.onProgress = true;
    let [err] = await to(this.api.POST(url, params).toPromise());
    if (err) {
      this.showErr(err);
      return item.onProgress = false;
    } else {
      item.podConfirmed = undefined;
    }
    item.onProgress = false;
    // this.refreshPodChange();
  }

  onBtnDelete = (item) => {
    this.confirmDeletion({
      message: "Are you sure you want to delete this POD?",
      txtBtnOk: this.txtDelete,
      fnOk: () => {
        this.callApiDelete(item);
      },
    });

  }

  async callApiDelete(item){
    let params: any = {};
    params.uploadPodFileId = item._id;
    if(item?.taskId){
      params.taskIds = [item.taskId]
    }else{
      params.taskIds = item.taskIds;
    }
    let url = `${Const.APIV2(Const.APIURI_TASKS)}/deletePodForListTasksOfStop`;
    this.api.POST(url, params).subscribe(
      (resp) => {
        this.showSuccess("Your POD has been deleted successfully.");
        this.refreshPodChange(true)
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  onBtnDownload = (item) => {
    return this.downloadAttachedFile(item);
  }

  isConfirmPOD(item) {
    if (item?.podConfirmed?.when) return true;
    return false;
  }

  public onBtnNext(){
    this.podService.handleChangePod('next')
  }

  public onBtnBack(){
    this.podService.handleChangePod('back');
  }

  private countTaskOfPOD(item) {
    const tasks = this.getTasksOfPOD(item)
    item.countTasks = tasks.length;
    item.tasks = tasks
    item.taskString = tasks.map(task => WarpId.showShipment((<any>task)?.shipment?.warpId)).join(', ');
  }

  public getTasksOfPOD(podItem) {
    const tasks = this.podService.getSelectedJob()?.tasks || []
    let podExistInTasks = tasks.filter(task => {
      const podArr = task?.podArr || [];
      return podArr.find(pod => pod._id == podItem._id)
    });
    return podExistInTasks || [];
  }
  public getListStops(){
    const stops = this.podService.getStops();
    if(!stops.length) return [];
    const displayStops = stops.map((stop: StopEntity, index) => {
      return {
        id: stop?.getId(),
        type: stop?.getType(),
        index: index + 1,
        tasks: stop.getTasks()
      }
    })
    return displayStops;
  }

  public onBtnViewIssue() {
    let taskId;
    if(this.presentPod?.taskId) taskId = this.presentPod?.taskId;
    else taskId = this.presentPod?.taskIds[0];
    let taskIds = [taskId];
    let jobId = this.podService.getSelectedJob()?.id;
    DrawerService.openDrawer1(Issue, {
      nzContentParams: {
        podItem: this.presentPod,
        refreshPodChange: () => {
          this.refreshPodChange()
        }
      },
      nzWidth: 400,
      nzWrapClassName: 'wrap-drawer route-pod-drawer',
    });
  }

  private getIssue(){
    this.isLoadingIssue = true;
    this.api.GET(`${Const.APIURI_CONVERSATIONS}/?subjectId=${this.presentPod?._id}&subjectType=pod&type=note`).subscribe(
      (resp) => {
        this.isLoadingIssue = false;
        this.displayInfo['totalIssue'] = resp?.data?.count || 0;
      },
      (err) => {
        // this.showErr(err);
        this.isLoadingIssue = false;
      }
    );
  }

  public get appliedPodText(){
    if(!this.presentPod?.taskId) return 'this stop';
    return `${this.displayInfo.applyTasks.length} tasks`
  }

  refreshDataAfterAssignPod(){
    let jobId = this.podService.getSelectedJob()?.id;
    let stopId = this.podService.getSelectedStop().getId();
    this.podService.fetchRoute(jobId, stopId);
  }
}
