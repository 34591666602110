import { Injectable } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { Job, ShipmentItem } from "@wearewarp/types/data-model";
import { AttachedFile } from '@wearewarp/types/data-model';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import StopEntity from "./Entity/StopEntity";
import ShipmentEntity from "./Entity/ShipmentEntity";
import TaskEntity from "./Entity/TaskEntity";
import { AttachedFileUtil } from "@services/attached-file-util";
import { takeUntil } from "rxjs/operators";


export interface JobPaginationInfo {
  count: number,
  limit: number,
  skip: number,
  total: number
}
export interface PODLocalFile extends AttachedFile {
  localUrl?: any,
  taskId?: string, //POD level Shipment thì có trường taskId này
  stopId?:string,
  taskIds?: string // POD level stop thì không có trường taskId mà chỉ có taskIds 
}

var CACHE_LOCAL_PODS: Map<string, any> = new Map();

@Injectable({
  providedIn: 'root'
})
export class PodService {
  private selectedJob: Job;
  private selectedStop: StopEntity;
  private stopEntities: StopEntity[];
  private podArray: PODLocalFile[] = []; //lưu pod của stop hiện tại để xử lí next, back 
  private shipmentItemsMap: Map<string, ShipmentItem> = new Map();
  private shipmentEntities: Map<string, ShipmentEntity> = new Map();
  private shouldSelectedThisPodAfterDeleted;
  public loading : BehaviorSubject<boolean> = new BehaviorSubject(true);
  public notifyCancelReq = new Subject<boolean>();
  public job: BehaviorSubject<any> = new BehaviorSubject(null);
  public onSelectedStop: BehaviorSubject<any> = new BehaviorSubject(null);
  public selectedPod: BehaviorSubject<any> = new BehaviorSubject(null);
  private presentPod: PODLocalFile;
  private podArrayMap: Map<string, PODLocalFile[]> = new Map(); //lưu pod của job 
  private taskEntities: Map<string, TaskEntity> = new Map();
  
  constructor(private api: ApiService) { }
  public async fetchJobSelected() {
    let promises = [
      this.fetchStops(),
      this.fetchTasks(),
      this.fetchShipments(),
      this.fetchShipmentItems()
    ];
    await Promise.all(promises).catch(e => {
      console.log(e);
    })
  }

  

  public async fetchStops() {
    try {
      let url = Const.APIV2(`${Const.APIURI_POD_CONFIRMATION}/${this.selectedJob.id}/pods`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data.list_data;
      this.stopEntities = await Promise.all(data.map(async (stop, index) => (await new StopEntity(this).setIndex(index).init(stop))));
    }
    catch (e) {
      console.log(e)
    }
  }

  public async fetchTasks(): Promise<void> {
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.selectedJob.id}/tasks`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data?.list_data;
      for (let item of data) {
        const taskEntity = new TaskEntity(this).init(item);
        this.taskEntities.set(taskEntity.getId(), taskEntity);
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  public async fetchShipments(): Promise<void> {
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.selectedJob.id}/shipments`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data?.list_data;
      for (let item of data) {
        const shipmentEntity = new ShipmentEntity(this).init(item);
        this.shipmentEntities.set(shipmentEntity.getId(), shipmentEntity);
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  public async fetchShipmentItems(): Promise<void> {
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.selectedJob.id}/shipment_items`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data?.list_data;
      for (let item of data) {
        this.shipmentItemsMap.set(item.id, item);
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  public async fetchRoute(jobId: string, stopId?: string, podId?: string, taskId?: string, isDelete?: boolean, isUpload?: boolean) {
    try {
      this.loading.next(true);
      //action xoá pod cần tìm ra pod liền kề để focus sau khi fetch lại job
      if(isDelete){
        if (this.podArray.length <= 1) this.shouldSelectedThisPodAfterDeleted = null;
        else {
          let index;
          if (this.presentPod?.taskId) {
            for (let i = 0; i < this.podArray.length; i++)
              if (this.podArray[i]?.taskId && this.podArray[i]?.taskId === this.presentPod?.taskId && this.podArray[i]?._id === this.presentPod._id) {
                index = i;
                break;
              }
          } else {
            for (let i = 0; i < this.podArray.length; i++)
              if (!this.podArray[i]?.taskId && this.podArray[i]?._id === this.presentPod._id && this.podArray[i]?.stopId === this.presentPod?.stopId) {
                index = i;
                break;
              }
          }
          if (index === 0) this.shouldSelectedThisPodAfterDeleted = this.podArray[1];
          else this.shouldSelectedThisPodAfterDeleted = this.podArray[index - 1]
        }
      }
      
      //đổi route clear podArrayMap 
      if(this.selectedJob?.id !== jobId){
        this.podArrayMap.clear();
        this.clearCache()
      }
      let url = `${Const.APIURI_JOBS_V2}/${jobId}/with-completedWhenBy`;
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data;
      this.selectedJob = data;
      await this.fetchJobSelected();
      for (let stop of Array.from(this.stopEntities.values())) {
        let pods = this.filterPodForStop(stop.getPods(), stop.toJSON().taskIds);
        pods = this.processPods(pods);
        this.podArrayMap.set(stop.getId(), pods);
      }
      this.selectedStop = this.stopEntities[0];
      // stopId, podId va taskId la thong tin de tim pod dang chon trc khi upload
      if(stopId){
        this.selectedStop = this.stopEntities.find((it: StopEntity) => it.getId() === stopId)
      }
      this.podArray = [];
      Array.from(this.podArrayMap.values()).forEach(arr => {
        this.podArray.push(...arr)
      });
      this.presentPod = this.podArray.length? this.podArray[0] : null;
      if(stopId){
        this.presentPod = this.podArray.filter(it => it?.stopId === stopId)?.[0];
      }
      if(podId){
        let pods = this.podArray.filter((it: PODLocalFile) => it._id === podId && it?.stopId === stopId);
        if(taskId){
          this.presentPod = pods.find(it => (it?.taskId === taskId));
        }else{
          this.presentPod = pods.find(it => (!it?.taskId));
        }
      }
      if(isUpload){
        let podOfTask;
        //pod cua shipment
        if(taskId){
          podOfTask = this.podArray.filter(it => (it?.taskId === taskId));
        }else{
          //pod cua stop
          podOfTask = this.podArray.filter(it => (!it?.taskId && it?.stopId === stopId))
        }
        this.presentPod = podOfTask[podOfTask.length - 1];
        if(!this.presentPod) this.presentPod = this.podArray[0]
      }
      if(isDelete){
        this.presentPod = this.shouldSelectedThisPodAfterDeleted
      }
      this.job.next(this.selectedJob);
      this.onSelectedStop.next(this.selectedStop);
      this.selectedPod.next(this.presentPod)
      this.loading.next(false)
    }
    catch (e) {
      console.log(e)
    }
  }

  

  public getStops() {
    return this.stopEntities;
  }

  public getSelectedJob() {
    return this.selectedJob;
  }

  

  private processPods(podArray) {
    return podArray.map((item, index) => {
      return {
        ...item,
        index,
        localUrl: () => this.attachedFileUrl(item),
        isPdf: AttachedFileUtil.isPdf(item),
        createdAt: item?.insert.when,
        podConfirmed: item?.podConfirmed || {},
      };
    });
  }

  public getSelectedStop() {
    return this.selectedStop;
  }

  public async setSelectedStop(stopId: string, podId?: any, taskId?: string) {
    this.selectedStop = this.stopEntities.filter(stop => (stop.getId() === stopId))[0];
    this.podArray = this.podArrayMap.get(this.selectedStop.getId());
    this.onSelectedStop.next(this.selectedStop)
    //stop hasn't any pod
    if (!this.podArray.length) {
      this.presentPod = null;
      this.selectedPod.next(null);
    }else{
      this.presentPod = this.podArray[0];
      this.selectedPod.next(this.presentPod)
    }
  }

  public setSelectedPod(podId: string, stopId?: string, taskId?: string){
    let arr;
    if(stopId){
      arr = this.podArray.filter(it => it?.stopId === stopId);
    }
    if(taskId){
      let pod = arr.find(it => (it?._id === podId && it?.taskId === taskId));
      this.presentPod = pod
      this.selectedPod.next(pod)
    }else{
      let pod = arr.find(it => (!it?.taskId && it?._id === podId));
      this.presentPod = pod
      this.selectedPod.next(pod)
    }
  }

  public getStopById(stopId) {
    return this.stopEntities.filter(stop => (stop.getId() === stopId))[0];
  }

  public setPresentPod(index: number) {
    if (index >= this.podArray.length || index < 0) return;
    this.presentPod = this.podArray[index];
    this.selectedPod.next(this.presentPod);
  }

  public handleChangePod(type: string) {
    if (this.podArray.length === 0) return;
    let index = this.podArray.indexOf(this.presentPod);
    switch (type) {
      case 'next': {
        this.setPresentPod(index + 1); break;
      }

      case 'back':
        this.setPresentPod(index - 1); break;
      default: break;
    }
  }


  public getPodByStop(stopId: string){
    return this.podArrayMap.get(stopId)
  }

  public getShipmentById(shipmentId: string) {
    return this.shipmentEntities.get(shipmentId);
  }

  public getShipmentItemById(itemId: string) {
    return this.shipmentItemsMap.get(itemId);
  }

  public getTaskById(taskId: string) {
    return this.taskEntities.get(taskId);
  }

  public getTaskEntities() {
    return Array.from(this.taskEntities.values());
  }

  isConfirmPOD(item) {
    if (item.podConfirmed?.when) return true;
    return false;
  }

  public filterPodForStop(pods: any[], taskIds: string[]) {
    let podAllTasks = pods.filter(it => !it?.taskId); //stop level
    let podEachTask = pods.filter(it => (it?.taskId));// shipment level
    let flag = 0;
    for (let taskId of taskIds) {
      let i: any = {};
      i.pods = podEachTask.filter(it => {
        return it.taskId === taskId;
      }) || [];
      if (i.pods.length !== podAllTasks.length) {
        flag = 1;
        break;
      }
    }
    if (!flag) return podAllTasks;
    else return pods;
  }

  public async attachedFileUrl(pod: PODLocalFile, ...otherParams) {
    if(pod.uploadProgress == 'UPLOADING'){
      pod.localUrl = 'https://warp-public-media.s3.us-west-2.amazonaws.com/images/photo-uploading.png';
      return pod.localUrl;
    }

    const cached = CACHE_LOCAL_PODS.get(pod._id);
    if(cached) pod.localUrl = cached;
    if (pod.localUrl) return pod.localUrl;
   
    if(AttachedFileUtil.isPdf(pod)){
      this.loading.next(true)
      const podUrl = AttachedFileUtil.attachedFileUrl(pod);
      const data = await this.api.download(podUrl).toPromise();
      const file = new Blob([data], { type: pod.type });
      const fileURL = URL.createObjectURL(file);
      CACHE_LOCAL_PODS.set(pod._id, fileURL);
      this.loading.next(false)
      return podUrl;
    }
    const podUrl = AttachedFileUtil.attachedFileUrl(pod, true);
    const response = await this.api.GET(`${podUrl}?no-redirect=1`).pipe(takeUntil(this.notifyCancelReq)).toPromise();
    if(response){
      pod.localUrl = response?.data?.url;
    }    
    return pod.localUrl;
  }

  private async clearCache(){
    const caches = Array.from(CACHE_LOCAL_PODS.values());
    caches.map(url => URL.revokeObjectURL(url));
    CACHE_LOCAL_PODS.clear()
  }


  public getPodArray(){
    return this.podArray;
  }
  public getPodArrayMap(){
    return this.podArrayMap;
  }

  public getSelectedStopIndex(){
    let index = 0;
    for(let i = 0; i< this.stopEntities.length; i++){
      if(this.stopEntities[i].getId() === this.selectedStop.getId()) index = i;
    } return index + 1;
  }

  public clearOldData(){
    this.job.next(null);
    this.onSelectedStop.next(null);
    this.selectedPod.next(null);
    this.shipmentEntities.clear();
    this.shipmentItemsMap.clear();
    this.taskEntities.clear();
  }
}
