<div *ngIf="hasData()" style="width: 100%;">
    <div class="stop">
        <div class="stop-title" (click)="toggleCollapse()">
            <div class="flex align-center">
                <div>
                    <i *ngIf="!expanded" class="btn-icon icon arrow_drop_down right5"></i>
                    <i *ngIf="expanded" class="btn-icon icon arrow_drop_up right5"></i>
                </div>
                <div>Stop {{index}} - {{getTypeText(stop)}}</div>
            </div>
            <div>
                <nz-tag class="tag" [nzColor]="getStatusColor(stop)">{{getStatusText(stop)}}</nz-tag>
            </div>
        </div>
        <div collapse-animation [expanded]="expanded">
            <div class="stop-level" id="stop-{{displayInfo.id}}">
                <div class="info">
                    <div class="address">
                        <span>{{displayInfo.locationName}}, {{displayInfo.addr}}</span>
                    </div>
                    <div>
                        <span class="label">Arrived: </span> <span>{{displayInfo.actualArrived}}</span>
                    </div>
                    <div>
                        <span class="label">{{displayInfo.isScheduled ? 'Appointment' : 'Scheduled'}}: </span> 
                        <span>{{displayInfo.appointment || 'N/A'}} {{displayInfo.appointment ? displayInfo.timezone : ''}}</span>
                    </div>
                    <div class="refNum">
                       <span class="label"> Ref no: </span>
                        <span *ngIf="!isShowRef()">N/A</span>
                        <ng-container *ngIf="isShowRef()" >
                            <nz-tag *ngFor="let ref of displayInfo.refNums" [nzColor]="'rgba(51, 65, 85, 0.12)'" style="color: #191A23; font-size: 12px; font-weight: 400; max-width: 440px; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;" [nzTooltipTitle]="ref" nzTooltipPlacement="bottom" nz-tooltip>{{ ref }}</nz-tag>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="displayInfo?.tasks?.length > 1" class="pods" list-pod-pod-confirmation [pods]="displayInfo.podStopLevel" [stop]="stop"></div>
                <div>
                    <a *ngIf="!expandShipments" (click)="toggleCollapseShipment()">View {{displayInfo.tasks.length}} shipment{{displayInfo?.tasks?.length > 1 ? 's':''}}</a>
                    <a *ngIf="expandShipments" (click)="toggleCollapseShipment()">Hide shipment{{displayInfo?.tasks?.length > 1 ? 's':''}}</a>
                </div>
            </div>
            <div collapse-animation [expanded]="expandShipments">
                <div class="task-container">
                    <ng-container *ngFor="let task of displayInfo.tasks" >
                        <div class="task-level" task-pod-confirmation [taskInfo]="task" id="task-{{task?.taskId}}" [stop]="stop"></div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!hasData()">
    <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 10, width: ['100%', '100%', '100%', '100%','100%','100%', '100%', '100%','100%','100%'] }"></nz-skeleton>
</div>
