<div style="height: 70vh; width: 100%; position: relative;">
    <ng-container *ngIf="fileUrl">
        <pdf-viewer (after-load-complete)="loadedPdfPod($event)" [fit-to-page]="1" [zoom-scale]="'page-height'" [src]="fileUrl" style="height: 70vh" [zoom]="zoom" [rotation]="rotation"></pdf-viewer>
    </ng-container>
    <div *ngIf="isLoading" style="width: 100%; height: 100%; position: absolute; top: 0; background-color: #fff;; z-index: 2;">
        <div  class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    </div>
    <div class="tool">
        <div class="group-btn">
            <button nz-button (click)="backFn()" nzType="text"><span class="icon" nz-icon nzType="left" nzTheme="outline"></span></button>
            <button nz-button (click)="zoomIn()" nzType="text"><span class="icon" nz-icon nzType="plus" nzTheme="outline"></span></button>
            <button nz-button (click)="zoomOut()" nzType="text"><span class="icon" nz-icon nzType="minus" nzTheme="outline"></span></button>
            <button nz-button (click)="rotateLeft()" nzType="text"><span class="icon" nz-icon nzType="undo" nzTheme="outline"></span></button>
            <button nz-button (click)="rotateRight()" nzType="text"><span class="icon" nz-icon nzType="redo" nzTheme="outline"></span></button>
            <button nz-button (click)="viewPodItem()" nzType="text"><span nz-icon nzType="fullscreen" nzTheme="outline"></span></button>
            <button nz-button (click)="nextFn()" nzType="text"><span nz-icon nzType="right" nzTheme="outline"></span></button>
        </div>
    </div>
</div>
